import Eiichiro_Oda_Avatar from "../../../images/Eiichiro_Oda_Avatar.webp";
import enel_face from "../../../images/enel_face.webp";
import loading from "../../../images/loading.gif";

const randomImageData = {
  odaAvatar: Eiichiro_Oda_Avatar,
  null: enel_face,
  loading: loading,
};

export default randomImageData;
